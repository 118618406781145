.apartments-selection {
    grid-template-rows: 1 1 1;
    height: 100%
  }

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .apartments-selection {
    grid-template-rows: 1 1 1;
    grid-template-columns: 1fr;
  }
}