.page-heading {
    text-align: center;
    padding-top: 10vh;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 10vh;
  }
  
  .page-heading h1 {
    font-size: 9vh;
    margin: 0;
    padding-bottom: 2vh;
    color: var(--custom-dark-gold);
    text-shadow:
      -1px -1px 0 var(--custom-dark-gray-blue),
      1px -1px 0 var(--custom-dark-gray-blue),
      -1px 1px 0 var(--custom-dark-gray-blue),
      1px 1px 0 var(--custom-dark-gray-blue),
      0px 0px 2px var(--custom-dark-gray-blue); 
  }
  
  .page-heading p {
    opacity: 0.6;
    font-size: 3vh;
  }
  
  
@media screen and ((max-width: var(--max-width)) or (orientation: portrait) or (max-aspect-ratio: 1/1)) {
  .page-heading h1 {
    font-size: 8vw;
  }
  
  .page-heading p {
    font-size: 3vw;
  }
}