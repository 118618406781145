.hero2-image {
  width: 100%;
  height: auto;
  margin-left: 0;
  margin-top: -10vh;
  margin-bottom: -5vh;
  overflow-x: hidden;
}

.hero2-image img {
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
}

.location {
  width: 80%;
  height: auto;
  margin-left: 10%;
  margin-top: -5vh;
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 60% auto;
}

.location img {
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
}

.location-legend {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
  padding-left: 2vw;
  margin-block: 10vh;
  margin-inline: 0vw;
  color: var(--custom-dark-gray-blue);
}

.location-legend p {
  margin-block: 0.1vh;
  font-size: 3vh;
}

.legend-row {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 3vh;
}

.legend-row i {
  margin-right: 1vw;
}

.building-complex-image {
  width: 70%;
  height: auto;
  margin-left: 10%;
  margin-top: -15vh;
  margin-bottom: -10vh;
}

.building-complex-image img {
  width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
}


@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .hero2-image {
    height: 30vh;
    display: flex;
    justify-content: center;
    margin-top: -5vh;
  }

  .hero2-image img {
    height: 100%;
    width: auto;
  }

  .location {  
    grid-template-columns: 100%;
  }

  .location-legend {
    margin-top: 1vh;
    margin-left: 2vw;
    font-size: 3vw;
    margin-bottom: 0;
  }
  
  .location-legend i {
    font-size: 3vw;
  }

  .location-legend p {
    font-size: 3vw;
  }

  .building-complex-image {
    margin-top: -5vh;
    margin-bottom: 0;
  }
}