.pdfButton {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 2vh;
  padding-inline: 3vw;   
  color: var(--custom-light-gray);
  font-family: 'light-font';
  background-color: var(--custom-dark-gray-blue);
  border-radius: 0;
}

.pdfButton p {
  font-size: 2vh;
  margin-block: 1vh;
}

.pdfButton:hover {
  color: var(--custom-light-gold);
  background-color: var(--custom-dark-gray-blue);
  transition: all 0.5s ease;
  cursor: pointer;
}

.pdfButton .pdfButton-icon {
  margin-right: 1vw;
  font-size: 3vh;
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
 .pdfButton p {
    font-size: 2.5vw;
  }  
 .pdfButton .pdfButton-icon {
    font-size: 3vw;
  }
}