.blog
{
  max-width: 80%;
  margin: auto;
  position: relative;
  margin-top: 3vh;
}

.blog-grid {
  margin: 0;
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 3vw;
  row-gap: 6vh;
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .blogCard-description {
    display: none;
  }
  .blogCard-image {
    height: 45vw;
  }
  .blogCard-content .blogCard-date {
    text-align: center;
    margin-bottom: 2vh;
    font-size: 3vw;
  }
  .blogCard-content .blogCard-title {
    text-align: center;
    font-size: 5vw;
  }
  .blogCard-content {
    text-align: center;
  }
  .blog-grid {
    display: block;
  }
  .blogCard {
    margin-top: 3vh;
  }
}
