.hero {
  background-repeat: no-repeat;
  position: relative;
  opacity: 0.9;
  height: 90vh;
  width: 100%;
  padding-top: 1vh;
  font-family: 'regular-font';
  text-align: right;
}

.hero h1 {
  color: var(--custom-dark-gold);
  font-size: 8vh;
  font-weight: bold;
  text-align: right;
  margin-top: 7vh;
  margin-bottom: 0;
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 20px var(--custom-dark-gray-blue); 
}

.hero p {
  color: var(--custom-dark-gray-blue);
  opacity: 0.8;
  font-size: 3vh;
  text-align: right;
  margin-top: 0;
}

.hero .heading .arrow-button button {
  font-weight: 700;
  color: var(--custom-dark-gray-blue);
  opacity: 0.8;
}

.hero .heading .arrow-button button:hover {
  opacity: 1;
}


@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .hero h1 {
    margin-top: 3vh;
    font-size: 10vw;
    text-align: center;
  }
  
  .hero p {
    margin-top: 1vh;
    font-size: 4vw;
    text-align: center;
  }

  .hero {
    text-align: center;
  }

  .hero .arrow-button {
    text-align: center;
  }

}
