.apartmentCard {
    border: 0.15rem solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
}

.apartmentCard:hover{
  border: 0.15rem solid var(--custom-dark-gray-blue);
}

.apartmentCard-image {
  opacity: .7;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 40vh;
}

.apartmentCard-image:hover {
  opacity: 1;
}

.apartmentCard-content {
  padding-top: 1vh;
  padding-bottom: 1vh;
  position: relative;
  display: grid;
  grid-template-columns: 5fr 4fr;
  grid-template-rows: auto auto;
  font-size: 0.9vw;
  font-weight: bold;
  color: var(--custom-light-gray);
  background: var(--custom-dark-gray-blue)
}

.apartmentCard-content .type, 
.apartmentCard-content .building,
.apartmentCard-content .floor,
.apartmentCard-content .sqft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.title {
  padding-block: 1vh;
  padding-left: 4%;
}

.value {
  padding-block: 1vh;
  padding-left: 2%;
}

.btn {
  margin-top: 1vh;
  text-align: center;
  font-size: 4vh;
}

@media screen and ((max-width: var(--max-width-2)) and (orientation:landscape) and (min-aspect-ratio: 1/1)) {
  .apartmentCard-content {
    font-size: 1.2vw;
    padding-block: 0.7vh;
  }

  .title {
    padding-block: 0.7vh;
  }

  .value {
    padding-block: 0.7vh;
  }
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .apartmentCard-image {
    height: 30vh;
  }

  .apartmentCard-content {
    font-size: 2.5vw;
    padding-block: 0.5vh;
  }

  .title {
    padding-block: 0.5vh;
  }

  .value {
    padding-block: 0.5vh;
  }
}