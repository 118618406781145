.logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.logo img {
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 100%;
    max-height: 100%;
}

.logo-junona {
    background-color: var(--custom-junona-gold);
}