.home-paragraph {
  width: 95%;
  position: relative;
  margin-bottom: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.home-paragraph h2 {
  font-size: 7vh;
  margin-top: 10vh;
  margin-bottom: 0;
  font-weight: bolder;
  color: var(--custom-dark-gold);
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 2px var(--custom-dark-gray-blue); 
}

.home-paragraph p {
  color: var(--custom-dark-gray-blue);
  opacity: 0.8;
  font-size: 3vh;
  margin-top: 2vh;
}

.home-paragraph .arrow-button button {
  color: var(--custom-dark-gray-blue)
}

.home-paragraph .arrow-button button:hover {
  color: var(--custom-very-dark-gold)
}


@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .home-paragraph h2 {
    font-size: 9vw;
  }

  .home-paragraph p {
    font-size: 4vw;
  }

  .home-paragraph .arrow-button button {
    font-size: 3.5vw;
  }
}