:root {
  --custom-dark-gray-blue: #22242e;
  --custom-dark-gray-blue-transparent: rgba(34, 36, 46, 0.9);
  --custom-light-gray: #f0f3ff;
  --custom-light-gold: #f5e056;
  --custom-dark-gold: #CB9E4C;
  --custom-very-dark-gold: #AC6E2B;
  --custom-junona-gold: #D2B933;
  --max-width: 40rem;
  --max-width-2: 62rem;
}

@font-face {
  font-family: "regular-font";   
  src: local("SweetSansProMedium"),
    url("./fonts/SweetSansProMedium.otf") format("opentype");
}
@font-face {
  font-family: "title-font";   
  src: local("Pulse"),
    url("./fonts/Pulse.otf") format("opentype");
}
@font-face {
  font-family: "light-font";  
  src: local("SweetSansProLight"),
    url("./fonts/SweetSansProLight.otf") format("opentype");
}

body {
  font-family: 'light-font';
  background-color: var(--custom-light-gray);
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  text-align: center;
}

.container {
  max-width: 80%;
  margin: 0 10% 0 10%;
  position: relative;
}

.container-title h1 {
  text-align: center;
  width: 100%;
  margin-bottom: 1.8rem;
  font-size: 3rem;
}

.btn {
  font-size: 1rem;
  padding: 1.5rem 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 2rem;
  background-color: #f2f2f2;
}

.btn:hover {
  cursor: pointer;
  background: transparent;
}

h1 {
  font-size: 2.2rem;
  font-weight: 1000;
  margin-top: 0;
}

h2 {
  font-size: 1.8rem;
  font-weight: 900;
}

h3 {
  font-size: 1.5rem;
  font-weight: 800;
}

h4 {
  font-size: 1.25rem;
  font-weight: 750;
}

p {
  font-size: 1.1rem;
  font-weight: 700;
}

a {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  cursor: pointer;
}

.slider-title
{
  font-weight: bold;
  margin-bottom: .4rem;
}

/* Set the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Style the track (the background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Style the thumb (the moving part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Hover state of the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

