header {
  background-color: var(--custom-dark-gray-blue);
  padding-top: 4vh;
  padding-bottom: 4vh;
  padding-inline: 5vw;
  position: sticky;
  top: 0;
  z-index: 99999;
  border-bottom: 0.3px solid var(--custom-light-gray);
  font-family: 'light-font';
  display: grid;
  grid-template-columns: 35% 15% 50%;
  grid-template-rows: 7vh;
}

header .menu {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

header .menu .show,
header .menu .noshow {
  display: flex;
  width: 100%;
  justify-content: space-around;
  list-style: none;
}

header li {
  display: flex;
}

header a {
  transition: 0.1s;
  color: var(--custom-light-gray);
  font-size: 1vw;
  font-family: 'light-font';
  text-decoration: none;
}

header a:hover {
  font-weight: 580;
  color: var(--custom-light-gold);
  transition: all 0.5s ease-in-out;
}

header .toggle {
  display: none;
  height: 1vh;
}

@media screen and ((max-width: var(--max-width)) or (orientation: portrait) or (max-aspect-ratio: 1/1)) {
  header {
    grid-template-columns: 100%;
    grid-template-rows: 55% 25% 20% 0%;
    height: 5vh;
    padding-top: 3vh;
    padding-bottom: 1vh;
    padding-inline: 7vw;
  }
  header .logo-inline {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    max-width: 100%;
    z-index: 2;
  }
  header .menu .noshow {
    display: none;
  }
  header .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  header .toggle button {
    font-size: 2vh;
    color: var(--custom-light-gray);
    background-color: var(--custom-dark-gray-blue);
    border: 0;
  }
  header .menu {
    height: 0;
    position: absolute;
    width: 100%;
  }
  header .menu .show {
    display: flex;
    flex-direction: column;
    position: sticky;
    z-index: 1;
    padding-inline: 0;
    padding-top: 48vh;
    padding-bottom: 3vh;
    background-color: var(--custom-dark-gray-blue);
    border-bottom: 0.3px solid var(--custom-light-gray);
  }
  header .menu .show li {
    display: flex;
    padding-block: 0.4vh;
    justify-content: center;
  }
  header a {
    font-size: 2vh;
  }
}
