.citation-card {
  padding-inline: 2vw;
  margin: 2vh;
  border-radius: 1rem;
  border: 0.09rem solid var(--custom-dark-gray-blue);
  box-shadow: 0px 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
  background-color: var(--custom-dark-gray-blue);
}

.citation-card h2 {
  font-size: 2vw;
  text-align: center;
  color: var(--custom-light-gray);
}

.citation-card p {
  font-size: 1.3vw;
  text-align: center;
  color: var(--custom-light-gray);
  opacity: 0.6;
}


@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .citation-card {
    padding-inline: 4vw;
  }
  .citation-card h2 {
    font-size: 5vw;
  }
  .citation-card p {
    font-size: 3.5vw;
  }
}