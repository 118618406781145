.logo-inline {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }
  
.logo-inline img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    padding-bottom: 1vh;
  }