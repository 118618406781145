.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 80%;
}

.contact-info-box {
  background-color: var(--custom-dark-gray-blue);
  height: auto;
  width: 100%;
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: block;
  text-align: left;
  padding-inline: 0;
  max-width: 100%;
}

.contact-circle {
  height: 20vh;
  width: 20vh;
  background-color: var(--custom-dark-gold);
  border-radius: 50%;
  position: relative;
  top: -10vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-circle .contact-icon {
  display: flex;
  position: relative;
  height: 8vh;
  width: 8vh;
}

.contact-circle a:hover {
  position: relative;
  height: 9vh;
  width: 9vh;
  transition: all 0.5s ease-in-out;
}

.contact-circle .contact-icon svg {
  position: relative;
  height: 100%;
  width: 100%;
  color: var(--custom-dark-gray-blue);
}

.contact-info-box h3 {
  display: block;
  color: var(--custom-light-gray);
  text-align: center;
  position: relative;
  top: -10vh;
  font-size: 4vh;
}

.contact-info-box p {
  display: block;
  color: var(--custom-light-gray);
  text-align: center;
  position: relative;
  top: -10vh;
  font-size: 2.5vh;
  opacity: 0.5;
  padding-inline: 3vw;
}

.contact-data p {
  opacity: 0.8;
  margin-block: 0.5vh;
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .contact .contact-options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
  }

  .contact-info-box {
    margin-top: 15vh;
    margin-bottom: 2vh;
  }

  .contact-info-box h3 {
    font-size: 5vw;
  }

  .contact-info-box p {
    font-size: 4vw;
    padding-inline: 6vw;
  }

}