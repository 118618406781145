.infoButton-link .infoButton {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding-block: 1vh;
  padding-inline: 3vw;   
  color: var(--custom-light-gray);
  font-family: 'light-font';
  background-color: var(--custom-dark-gray-blue);
  border-radius: 0;
}

.infoButton-link .infoButton p {
  font-size: 3vh;
  margin-block: 1vh;
}

.infoButton-link .infoButton:hover {
  color: var(--custom-light-gold);
  background-color: var(--custom-dark-gray-blue);
  transition: all 0.5s ease;
  cursor: pointer;
}

.infoButton-link .infoButton .infoButton-icon {
  margin-right: 1vw;
  font-size: 3vh;
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .infoButton-link .infoButton p {
    font-size: 3vw;
  }  
  .infoButton-link .infoButton .infoButton-icon {
    font-size: 3vw;
  }
}