.catalog {
    margin-top: 5vh;
    position: relative;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0.2rem 0.4rem rgb(0 0 0 / 25%);
    display: grid;
    grid-template-rows: 90vh 80vh;
    margin-bottom: 5vh;
  }
.apartment-main-content .apartment-sold-tag {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: auto;
  padding: 1vh;
}
.apartment-main-content .apartment-sold-tag img {
  max-height: 100%;
}
.apartment-main-content {
  width: 100%;
  display: grid;
  grid-template-columns: 30vw auto;
}

.apartment-main-content img {
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  box-sizing: border-box;
  object-fit: contain;
}

.info-table {
  height: auto;
  padding-block: 3vh;
  padding-inline: 3vw;
  background-color: var(--custom-dark-gold);
  color: var(--custom-dark-gray-blue);
  text-align: left;
}
.info-table h1 {
  font-size: 8vh;
}

.info-table h4 {
  font-size: 5vh;
}

.info-table p {
  font-size: 3vh;
}
.apartment-render {
  background-color: var(--custom-dark-gray-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}
.apartment-visuals {
  display: grid;
  grid-template-columns: 30vw auto;
  grid-template-rows: 100%;
}

.apartment-visuals h2 {
  font-size: 5vh;
}

.apartment-visuals img {
  height: auto;
  max-height: 100%;
  width: auto;
  max-width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  object-fit: contain;
}
.position-visual {
  padding: 1rem;
  background-color: var(--custom-dark-gray-blue);
}
.position-visual {
  color: var(--custom-light-gray);
}
.rooms-visual {
  text-align: left;
  padding: 1rem;
  padding-inline: 4vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: var(--custom-dark-gold);
}

.rooms-visual p {
  font-size: 3vh;
}

  
@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .catalog {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
  }

  .apartment-main-content .apartment-sold-tag {
    position: relative;
    background-color: var(--custom-dark-gray-blue);
  }

  .apartment-main-content {
    grid-template-columns: 1fr;
    grid-template-rows: 60vh 8vh 45vh;
  }

  .apartment-visuals {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }

  .apartment-visuals h2 {
    font-size: 8vw;
  }

  .info-table h1 {
    font-size: 10vw;
  }
  
  .info-table h4 {
    font-size: 7vw;
  }
  
  .info-table p {
    font-size: 5vw;
  }

  .rooms-visual {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .rooms-visual p {
    font-size: 4vw;
  }
}