.project-details {
    margin-top: 10vh;
    width: 90%;
    margin-left: 10%;
}

.project-details h1 {
  text-align: right;
  font-size: 5vh;
  color: var(--custom-dark-gold);
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 2px var(--custom-dark-gray-blue); 
}

.project-details p {
    text-align: left;
}

.project-details-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.project-details .project-details-table .detail-info-row {
    width: 100%;
    border: .5px solid var(--custom-dark-gray-blue); 
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}

.project-details .project-details-table .detail-info-row p {
    margin-right: 1vw;
    font-size: 2.5vh;
}

.project-details .project-details-table .detail-info-row i {
    padding-inline: 1vw;
    font-size: 2.5vh;
}

.project-details .pdf-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 4vh;
    padding-inline: 2vw;
}

.pdf-buttons {
    display: flex;
    flex-direction: row;
}


@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
    .project-details {
        margin-top: 12vh;
        margin-bottom: 0;
    }
    
    .project-details h1 {
        font-size: 6vw;
    }
    
    .project-details-table {
        grid-template-columns: 1fr;
    }
    
    .project-details .project-details-table .detail-info-row p {
        font-size: 3vw;
    }

    .project-details .project-details-table .detail-info-row i {
        font-size: 3vw;
    }
    
    .project-details .pdf-buttons {
        flex-direction: column;
    }
}