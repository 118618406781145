.search-container {
  margin-top: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5vw;
}

.price-slider,
.sqft-slider
 {
  width: 20vw;
  margin-right: 2vw;
}

.type-dropdown,
.floor-dropdown
{
  width: 10vw;
}

input[type="range"] {
  width: 100%;
}

select {
  width: 100%;
  padding: 1vw;
  border-radius: .4rem;
  font-size: 1.2vw;
  height: 8vh;
}

.search-button {
  margin-block: 10vh;
  width: 30vw;
  height: 7vh;
  padding: 1vh;
  background-color: var(--custom-dark-gray-blue);
  border: none;
  border-radius: .5rem;
  color: white;
  cursor: pointer;
  opacity: 0.7;
}

.search-button:hover {
  opacity:1;
}


@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .search-container {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .price-slider,
  .sqft-slider
  {
    margin-top: 2vh;
    width: 60vw;
  }

  .type-dropdown,
  .floor-dropdown
  {
    margin-top: 2vh;
    width: 30vw;
  }

  .search-button {
    width: 50vw;
  }
  
  .search-container {
    font-size: 4vw;
  }
  
  select {
    height: 10vw;
    font-size: 3vw;
  }

  .search-button {
    margin-top: 15vw;
  }
}