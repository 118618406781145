.slider {
  margin-top: 5vh;
  margin-bottom: 5vh;
  position: relative;
  width: 100%;
  overflow: hidden;  
  background-color: var(--custom-dark-gray-blue);
  box-shadow: 0px 0.2rem 0.4rem rgb(0 0 0 / 25%);
}

.slide.active {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: auto;
  height: 60vh;
  margin-block: 10vh;
  margin-inline: 5vw;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 1vw;
  font-size: 6vh;
  color: var(--custom-dark-gray-blue);
  z-index: 10;
  cursor: pointer;
  user-select: none;
  background-color: var(--custom-light-gray);
  border-radius: 50%;
}

.right-arrow:hover{
  color:var(--custom-dark-gold);
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 1vw;
  font-size: 6vh;
  color: var(--custom-dark-gray-blue);
  z-index: 10;
  cursor: pointer;
  user-select: none;
  background-color: var(--custom-light-gray);
  border-radius: 50%;
}

.left-arrow:hover {
  color:var(--custom-dark-gold);
}
  
.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
 
@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .slider {
    height: 50vh;
  }
  
  .image {
    width: auto;
    height: auto;
    max-width: 50vw;
    max-height: 40vh;
    margin-block: 5vh;
  }
  
  .left-arrow {
    font-size: 8vw;
  }

  .right-arrow{
    font-size: 8vw;
  }
}