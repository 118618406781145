.blogCard {
    border: 0.09rem solid var(--custom-dark-gray-blue);
    box-shadow: 0px 0.2rem 0.4rem rgba(0, 0, 0, 0.25);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0;
  }
  
  .blogCard-image {
    background-position: center;
    background-size: cover;
    height: 30vh;
  }

  .blogCard-content {
    padding-block: 2vh;
    padding-inline: 1.5vw;
    height: 35%;
  }

  .blogCard-title {
    margin: 0;
    font-size: 5vh;
    font-weight: bold;
    text-align: left;
  }

  .blogCard-date {
    margin: 0;
    font-size: 3vh;
    font-weight: 300;
    text-align: left;
    margin-top: 0.2vh;
  }

  .blogCard-description {
    margin: 0;
    font-size: 3vh;
    font-weight: 800;
    text-align: justify;
    margin-top: 1vh;
    margin-bottom: 3vh;
  }