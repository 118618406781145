.footer {
  background-color: var(--custom-dark-gray-blue);
  color: var(--custom-light-gray);
  padding: 5vh 0;
  margin-top: 10vh;
  font-family: 'light-font';
  font-size: min(1.3rem, 1.3vw);
}
  
.footer .container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
  
.footer-links {
  flex: 1;
  text-align: center;
}
  
.footer-title {
  margin-bottom: 5vh;
  font-weight: 580;
  font-size: min(1.5rem, 1.5vw);
}

.footer .social-links,
.footer .contact-info,
.footer .useful-links { 
  margin: 0;
  padding: 0;
}

.footer .social-links li,
.footer .contact-info li,
.footer .useful-links li {
  margin-bottom: 2vh;
}

.footer .social-links,
.footer .contact-info,
.footer .useful-links {
  align-items: center;
}

.footer .social-links a,
.footer .contact-info a,
.footer .useful-links a {
  color: var(--custom-light-gray);
  font-weight: 550;
  text-decoration: none;
  text-align: center;
}

.footer .social-links a:hover svg,
.footer .contact-info a:hover svg,
.footer .useful-links a:hover svg {
  color: var(--custom-light-gold);
}

.footer .social-links a:hover,
.footer .contact-info a:hover,
.footer .useful-links a:hover {
  font-weight: 580;
  color: var(--custom-light-gold);
  transition: all 0.5s ease-in-out;
}

.social-links a svg,
.contact-info a svg {
  width: min(1.8rem, 1.8vw);
  height: min(1.8rem, 1.8vw);
  padding-right: 1vw;
}

.contact-line i {
  padding-right: 0.8vw;
}

.social-links a:hover svg,
.contact-info a:hover svg {
  transition: all 0.5s ease-in-out;
}

.contact-info .contact-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.footer-line {
  height: 1px;
  opacity: 0.3;
  background-color: var(--custom-light-gray);
  margin: 10vh auto;
  width: 98%;
  margin-bottom: 5vh;
}

.copyright {
  font-size: 1.2vw;
  text-align: center;
  margin-top: 5vh;
  padding-inline: 10vw;
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .footer .container {
    flex-direction: column;
  }

  .footer-title {
    font-size: 5vw;
  }

  .footer-links {
    font-size: 3.3vw;
  }

  .footer-links a svg {
    height: 5vw;
    width: 5vw;
  }

  .copyright {
    font-size: 3vw;
  }
}