.blog-heading h2 {
    font-size: 8vh;
    text-align: left;
    margin-bottom: 1vh;
    color: var(--custom-dark-gray-blue);
}

.blog-heading h4 {
    font-size: 4vh;
    text-align: left;
    margin-top: 1vh;
    color: var(--custom-dark-gray-blue);
    opacity: 0.7;
}

.distancer-line {
    height: 1px;
    opacity: 0.7;
    background-color: var(--custom-dark-gray-blue);
    margin: 4vh auto;
    width: 100%;
}

.blog-text p {
    font-size: 3vh;
    text-align: justify;
}


@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
    .blog-heading h2 {
        font-size: 7vw;
        text-align: center;
    }
    
    .blog-heading h4 {
        font-size: 5vw;
        text-align: center;
    }

    .blog-text p {
        font-size: 4vw;
    }
}