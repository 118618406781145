.about {
  display: block;
}

.introducer {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 100%;
  padding-left: 10%;
  padding-right: 10%;
  background-color: var(--custom-dark-gray-blue-transparent);
  height: 90vh;
  padding-top: 1vh;
}

.introducer-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.introducer-text {
  margin-right: 8vw;
  margin-bottom: 1vh;
  color: var(--custom-light-gray);
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about .introducer-text h1 {
  font-size: 7vh;
  font-family: 'regular-font';
  text-align: left;
  font-weight: bolder;
  color: var(--custom-dark-gold);
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 2px var(--custom-dark-gray-blue); 
}

.introducer-text p {
  font-weight: 50;
  opacity: 0.9;
  font-size: 3vh;
  padding-left: 0.2vw;
  font-family: 'light-font';
  text-align: left;
}

.introducer .logo img {
  padding-bottom: 0;
}

.why-us {
  margin-top: 10vh;
  text-align: justify;
  padding-inline: 2vw;
}

.why-us h1 {
  font-size: 8vh;
  color: var(--custom-dark-gold);
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 2px var(--custom-dark-gray-blue); 
}

.about img {
  height: 100%;
  width: auto;
  max-width : 100%;
  max-height : 100%;
  object-fit: contain;
}

.about h1 {
  font-size: 8vh;
  color: var(--custom-dark-gold);
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 2px var(--custom-dark-gray-blue); 
}

.about p {
  font-size: 3vh;
}

.successful-projects {
  padding-top: 10vh;
  text-align: justify;
  padding-inline: 2vw;
  margin-bottom: 5.5vh;
}

.successful-projects h1 {
  font-size: 8vh;
  color: var(--custom-dark-gold);
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 2px var(--custom-dark-gray-blue); 
}

.successful-projects .blogHome .container {
  max-width: 100%;
  margin-inline: 0;
}

.successful-projects .blogHome {
  margin-top: 10vh;
}

.satisfied-customers h1 {
  text-align: justify;
}

.satisfied-customers {
  padding-top: 10vh;
}

.customers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
}

@media screen and ((max-width: var(--max-width)) or (orientation: portrait) or (max-aspect-ratio: 1/1)) {
  .customers {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
  }

  .introducer {
    grid-template-rows: 60% 40%;
    grid-template-columns: 100%;
    height: 90vh;
    padding-top: 1vh;
  }

  .introducer-text {
    margin-right: 0;
    align-items: center;
  }
  
  .introducer .introducer-text h1 {
    font-size: 10vw;
    font-family: 'regular-font';
    text-align: center;
  }

  .introducer-text p {
    font-size: 4vw;
  }

  .about p {
    font-size: 4vw;
  }

  .successful-projects {
    margin-bottom: 0vh;
  }

  .why-us .img-container {
    padding-inline: 3vw;
  }

  .why-us h1,
  .successful-projects h1,
  .satisfied-customers h1{
    font-size: 10vw;
  }
}