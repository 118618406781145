.arrow-button button {
  font-family: 'light-font';
  font-size: 3vh;
  color: var(--custom-light-gray);
  background-color: rgba(0,0,0,0);
  border-color: rgba(0,0,0,0);
  padding: 0;
  cursor: pointer;
}

.arrow-button i {
  padding-right: 1vw;
}

.arrow-button button:hover {
  color: var(--custom-light-gold);
  transition: 1s;
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .arrow-button button {
    font-size: 4vw;
  }
}