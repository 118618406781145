.apartmentCard-container {
    margin: auto;
    position: relative;
    display: grid;
    margin-bottom: 2vh;
    margin-top: 1vh;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 2vw;
    row-gap: 3vh;
}

.apartmentsOffer h1 {
  color: var(--custom-dark-gold);
  text-shadow:
    -1px -1px 0 var(--custom-dark-gray-blue),
    1px -1px 0 var(--custom-dark-gray-blue),
    -1px 1px 0 var(--custom-dark-gray-blue),
    1px 1px 0 var(--custom-dark-gray-blue),
    0px 0px 2px var(--custom-dark-gray-blue); 
}

@media screen and ((max-width: var(--max-width-2)) and (orientation:landscape) and (min-aspect-ratio: 1/1)) {
    .apartmentCard-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
    .apartmentCard-container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}