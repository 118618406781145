.nav-object {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 4fr; 
  margin-top: 4vh;
  margin-bottom: 5vh;
  width: 100%;
  height: 75vh;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.2rem 0.4rem rgb(0 0 0 / 25%);
}

.sidebar {
  display: grid;
  grid-template-rows: 4vw auto;
  position: relative;
  width: 100%;
  height: 100%;
}

.sidebar-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-inline-start: 1vw;
}

.sidebar-title p {
  text-align: left;
  font-size: 1.5vw;
  margin-top: 1.5vw;
  margin-bottom: 1vw;
}

.sidebar-selection {
  background-color: var(--custom-dark-gray-blue);
  display: grid;
  grid-template-rows: 1 1 1 1 1 1;
}

.sidebar-item {
  color: var(--custom-light-gold);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.sidebar-item p {
  font-weight: 100;
  font-size: 3vh;
  margin: 0;
  padding-left: 0.6vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.selected {
  background-color: var(--custom-very-dark-gold);
}

.object-main-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 20vh auto 10vh;
  padding-top: 0;
}

.main-content-title {
  display: block;
  position: relative;
  margin-top: 2vh;
  text-align: center;
}

.main-content-title h1 {
  font-size: 5vh;
  padding-top: 2vh;
}

.render {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.img-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.img-wrapper img {
  border: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.main-image, .overlay-image {
  position: relative;  
  object-fit: contain; 
}

.overlay-image {
  z-index: 1;
  opacity: 0.5;
}

.legend {
  display: inline-flex;
  align-items: baseline;
  position: absolute;
  right: 0;
  bottom: 0;
  margin-right: 1vw;
  margin-bottom: 1vh;
}

.legend p {
  font-size: 3vh;
  padding-inline-start: 0.2vw;
  padding-inline-end: 1vw;
  margin-block: 0;
}

@media screen and ((max-width: var(--max-width)) or (orientation:portrait) or (max-aspect-ratio: 1/1)) {
  .nav-object {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr; 
  }

  .object-main-content {
    padding-top: 0;
  }

  .sidebar {
    grid-template-rows: 5vh auto;
  }
  
  .sidebar-title {
    align-items: center;
  }

  .sidebar-title p {
    text-align: center;
    font-size: 4vw;
    margin-block: 5vh;
  }

  .sidebar-selection {
    grid-template-rows: 1 1 1;
    grid-template-columns: 1fr;
  }

  .sidebar-item p {
    font-size: 4vw;
    padding-left: 2vw;
  }

  .object-main-content {
    grid-template-rows: 8vh auto 6vh;
  }
  
  .main-content-title h1 {
    font-size: 6vw;
    padding-top: 2vh;
  }
  
  .legend p {
    font-size: 3.5vw;
  }

  .legend i {
    font-size: 3.5vw;
  }
}